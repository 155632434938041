@import 'src/styles/mixins';
@import 'src/styles/variables';

.button {
  border-radius: 50px;
  border: 2px solid $white;
  background: linear-gradient(90deg, #ff6800 0%, #ff4718 49.6%, #ff292e 100%);
  box-shadow: 0px 0px 10px 0px $primary;
  padding: 12px;
  max-width: 48px;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  top: -10px;
  position: absolute;
  cursor: pointer;
}

.wrapper {
  position: relative;
  width: 75px;
}

.container {
  cursor: pointer;
  width: 180px;
  min-width: 180px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.text {
  @include text(16px, 400, 22px);
  text-align: left;
  color: $white;
  z-index: 1;
  padding-left: 12px;
}

.buy {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
