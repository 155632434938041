@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  position: relative;
}

.menu {
  @include text-16;
  display: flex;
  gap: 4px;
  align-items: center;
  height: fit-content;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }

  span {
    transition: all 0.3s;
    white-space: nowrap;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  height: max-content;
  overflow: hidden;
  width: fit-content;
  max-width: 300px;
  position: absolute;
  left: 0;
  z-index: 10;
  background-color: $light-gray;
  border-radius: 0px 16px 16px 16px;

  transition:
    max-height 0.3s ease-in-out,
    margin-top 0.3s ease-in-out,
    padding-top 0.3s ease-in-out,
    padding-bottom 0.3s ease-in-out;
}

.hidden {
  max-height: 0px;
  overflow: hidden;
  padding: 0 12px;
}

.item {
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  overflow-x: hidden;
  height: 22px;
  min-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: $primary;
  }
}
