@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  display: flex;
  justify-content: flex-end;
  padding: 36px 0 0;
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: $white;
  margin-bottom: 14px;
  height: 134px;
  transition: background-color 1s;

  @include vp-1024 {
    flex-direction: column-reverse;
  }

  @include vp-767 {
    display: none;
  }

  &Hidden {
    background-color: transparent;
    width: fit-content;
    margin: 0 0 14px auto;

    .main {
      display: none;
    }

    &Active {
      width: 100%;
    }
  }
}

.logoMain {
  user-select: none;

  @include vp-1024 {
    display: none;
  }
}

.logo {
  margin-right: 24px;
  cursor: pointer;

  &MobileMain {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
    z-index: 10;
  }

  &Mobile {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 5;

    &Wrapper {
      width: 52px;
      height: 52px;
      background-color: $white;
      box-shadow: 0px 4px 12px 0px #00000026;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      cursor: pointer;
    }

    &Icon {
      width: 28px;
      height: 36px;
    }
  }
}

.main {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  opacity: 1;
  transition: all 0.1s;
  position: absolute;
  left: 0;

  @include vp-1024 {
    justify-content: flex-end;
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
  background-color: transparent;
}

.links {
  display: flex;
  gap: 24px;
  margin-top: 21px;
  flex-wrap: wrap;

  @include vp-1279 {
    width: 520px;
  }

  @include vp-1024 {
    justify-content: flex-end;
    gap: 12px;
  }
}

.actions {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  position: absolute;
  right: 0;

  @include vp-1024 {
    margin-top: 0;
  }

  &Active {
    width: 100%;
    flex-wrap: nowrap;
  }
}

.mobile {
  z-index: 10;
  position: relative;
}

.top {
  height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $white;
  justify-content: flex-end;

  @include vp-767 {
    padding: 0 16px 0 16px;
  }

  &Wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 16px 10px 16px;
    z-index: 5;

    @include vp-1024 {
      padding: 0;
    }
  }
}

.withSidebar {
  // right: 9px;
}

.empty {
  width: 36px;
  height: 36px;
}

.topDetailed {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 20px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;

  &Buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Button {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;
    border-radius: 50px;
    cursor: pointer;
    z-index: 6;
  }

  &Icon {
    width: 24px;
    height: 24px;
  }

  &Actions {
    display: flex;
    gap: 12px;
  }
}

.search {
  cursor: pointer;
}

.party {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  height: fit-content;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  height: 60px;
  padding: 0 8px;
  position: fixed;
  z-index: 10;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0px -1px 0px 0px $gray-light;
}
