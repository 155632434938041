@import 'src/styles/mixins';
@import 'src/styles/variables';

.button {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 46px;
  cursor: pointer;
}

.text {
  @include text(9px, 500, 12px);
  text-align: center;
  color: $gray;
}
