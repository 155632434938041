#root {
  --manrope: Manrope, sans-serif;
  --primary: #ff4718;
  --white: #ffffff;
  --gray: #979da5;
  --gray-light: #eceef4;
  --black: #18232e;
  --light-gray: #f5f6fb;
  --light-red: #fff7f7;
}

$manrope: var(--manrope);
$primary: var(--primary);
$white: var(--white);
$gray: var(--gray);
$gray-light: var(--gray-light);
$black: var(--black);
$light-gray: var(--light-gray);
$light-red: var(--light-red);
